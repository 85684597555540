@media (max-width: 767px) {

    .ant-picker-panels{
        display: flex !important;
        flex-direction: column !important;
    }

    .ant-picker-dropdown {
        max-width: 100% !important;
    }


    .ant-picker-cell-inner {
        font-size: 14px;
    }
}